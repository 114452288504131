<template>
	<div id="rootVM">
		<div id="pageTitle">
			<!-- (구)CCTV 위험지역 설정 -->
			<b>위험지역 설정</b>
		</div>
		<div class="panel panel-flat">
			<div class="panel-body" style="display: flex; align-items: center; justify-content: center; border: none">
				<!-- 위험지역구분코드 -->
				<div class="form-group" style="width: 480px; margin-top: 18px; margin-bottom: 5px">
					<label align="center" style="width: 150px">{{ cctvInfoMapForLabel.dangerZoneDvsnCd }}</label>
					<div class="select-wrapper">
						<select2 v-model="searchVM.dangerZoneDvsnCd" :options="option.dangerZoneDvsnCd" :disabled="!isCctv">
							<option value="">전체</option>
						</select2>
					</div>
				</div>
			</div>
		</div>
		<div class="contentWrap">
			<div id="treeView">
				<div class="demo-section wide k-content">
					<div class="treeview-flex">
						<div id="treeview-kendo"></div>
					</div>
				</div>
			</div>
			<div class="content" v-show="isCctv">
				<!-- 캔버스에 클릭한 좌표 찍기. 
				pointsInfo: 캔버스에서 찍은 점을 원본 좌표로 변환한 값 
				mousePoints: 캔버스 크기 대비 위치 0 ~ 1.0-->
				<!-- <div>
					<p ref="pointsInfo">No points yet</p>
					<p ref="mousePoints">No points yet</p>
				</div> -->
				<div style="display: flex; justify-content: space-between">
					<div>
						<h1>{{ curSiteNm }} - {{ curCctvNm }}</h1>
					</div>
					<div style="display: flex; justify-content: center; align-items: center">
						<span>ESC: 현재 그리던 폴리곤 삭제 / ctrl + `~: 되돌리기</span>
					</div>
				</div>
				<div ref="stage" id="stage">
					<!-- 위험지역 폴리곤을 그릴 캔버스 -->
					<canvas
						width="1060px"
						height="596px"
						ref="polygonLayer"
						id="polygon-layer"
						@mousedown="handleMouseDown($event)"
						@mouseup="setDraggableFalse($event)"
						@mousemove="mouseMove($event)"
					></canvas>
					<!-- 비디오의 연속된 프레임을 보여줄 이미지 -->
					<img id="video-layer" ref="video" :src="curCctvSrc" />
				</div>
				<div style="width: 1200px; height: 56px">
					<div style="display: flex; justify-content: space-between; padding-left: 60px; padding-right: 60px">
						<div>
							<div class="text-right">
								<!-- 캔버스에서 폴리곤 삭제 버튼 -->
								<button type="button" class="btn btn-labeled bg-dark" @click="clearPointsWithoutDBdatas">
									<b><i class="icon-stack-cancel"></i></b>
									클리어
								</button>
								<span style="margin-left: 10px">*클리어버튼 클릭시 DB에 저장되어 있지 않은 폴리곤을 지웁니다</span>
							</div>
						</div>
						<div class="text-right">
							<div class="col-lg-12" v-if="curCctvInfo.updDtm != ''" :key="keyForRerenderingUpdOutput">
								<div class="col-lg-6">
									<!-- 수정일 -->
									<label class="col-md-4 title" align="right" style="margin-top: 8px">{{ cctvInfoMapForLabel.updDtm }}:</label>
									<div class="row col-md-8 form-group">
										<input type="text" class="form-control" style="width: 150px" :value="curCctvInfo.updDtm | toDisplayDateTime" :disabled="true" />
									</div>
								</div>
								<div class="col-lg-6">
									<!-- 수정자 -->
									<label class="col-md-4 title" align="right" style="margin-top: 8px">{{ cctvInfoMapForLabel.updUser }}:</label>
									<div class="row col-md-8 form-group">
										<input type="text" class="form-control" style="width: 150px" :value="curCctvInfo.updUser" :disabled="true" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 위험지역구분코드 위험지역 또는 2인 1조일 때 위험지역 저장 layout -->
				<div style="width: 1200px; display: flex; justify-content: space-between">
					<!-- 위험지역구분코드 AI 탐지 지역 또는 AI 비탐지 지역일 때 위험지역 저장 layout -->
					<div
						ref="zoneNm"
						v-show="isCctv"
						v-if="searchVM.dangerZoneDvsnCd == '05' || searchVM.dangerZoneDvsnCd == '06'"
						style="flex-wrap: wrap; width: 100%; margin-left: 5px; margin-right: 5px; border: none"
						:key="zoneIndexForCount"
					>
						<div style="display: flex; margin-left: 5px; margin-bottom: 5px; min-height: 40px" v-for="(zoneName, index) in zoneNames" :key="index">
							<div style="height: 100%; display: flex; align-items: center">
								<input
									:id="'zoneName' + index"
									type="text"
									class="form-control zoneNames"
									style="width: 160px; border: none; display: inline-block; border: 1px solid #ddd; margin-right: 5px"
									v-model="zoneNames[index]"
									:key="index"
									:placeholder="placeholder"
									maxlength="50"
									@focus="focusedInput(index)"
								/>
								<div v-if="index != polygons.length">
									<!-- 대상물 수정 버튼 -->
									<button
										type="button"
										class="btn bg-info"
										style="font-weight: bold; height: 100%; margin-right: 5px"
										@click="updateObjectIds(index)"
									>
										대상물 수정
									</button>
									<!-- 폴리곤 삭제 버튼. 삭제버튼이 나오면 저장버튼은 나오지 않음 -->
									<button type="button" class="btn btn-warning" style="font-weight: bold; height: 100%; margin-right: 5px" @click="removeZone(index)">
										삭제
									</button>
									<span>*적용된 대상물 : {{ zoneObjectLabels[index] }}</span>
								</div>
								<!-- 폴리곤 저장 버튼 -->
								<button type="button" class="btn bg-primary" style="font-weight: bold; height: 100%; margin-right: 5px" @click="savePolygon" v-else>
									저장
								</button>
							</div>
						</div>
					</div>
					<div
						ref="zoneNm"
						v-show="isCctv"
						v-else
						style="display: flex; flex-wrap: wrap; width: 100%; margin-left: 5px; margin-right: 5px; border: none"
						:key="zoneIndexForCount"
					>
						<div
							style="display: flex; margin-left: 5px; margin-bottom: 10px; min-height: 40px; border: 1px solid #ddd"
							v-for="(zoneName, index) in zoneNames"
							:key="index"
						>
							<div style="height: 100%; display: flex; align-items: center">
								<input
									:id="'zoneName' + index"
									type="text"
									class="form-control zoneNames"
									style="width: 160px; border: none; display: inline-block; padding-left: 8px; padding-right: 5px"
									v-model="zoneNames[index]"
									:key="index"
									:placeholder="placeholder"
									maxlength="50"
									@focus="focusedInput(index)"
								/>
								<!-- 폴리곤 삭제 버튼. 삭제버튼이 나오면 저장버튼은 나오지 않음 -->
								<button
									type="button"
									class="btn btn-warning"
									style="font-weight: bold; height: 100%"
									@click="removeZone(index)"
									v-if="index != polygons.length"
								>
									삭제
								</button>
								<!-- 폴리곤 저장 버튼 -->
								<button type="button" class="btn bg-info" style="font-weight: bold; height: 100%" @click="savePolygon" v-else>저장</button>
							</div>
						</div>
					</div>
					<div>
						<!-- 데이터 적용 버튼 -->
						<button type="button" class="btn btn-labeled bg-primary" @click="saveData">
							<b><i class="icon-checkmark3"></i></b>
							적용
						</button>
					</div>
				</div>
			</div>
		</div>
		<div id="objectSearch" class="modal fade">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">대상물을 선택해주세요</h5>
					</div>
					<div class="modal-body">
						<div class="search" style="margin-bottom: 10px">
							<span style="margin-right: 10px">대상물:</span>
							<select2 v-model="modalSelectObject" style="width: 70%">
								<option value="">전체</option>
								<option v-for="item in option.useObjects" :key="item.objectId" :value="JSON.stringify(item)">
									{{ item.objectNm }}
								</option>
							</select2>
							<button type="button" class="btn btn-labeled bg-success" style="margin-left: 10px" @click="addModalObject">
								<b><i class="icon-checkmark3"></i></b>
								추가
							</button>
						</div>

						<div class="table-wrapper" ref="tableWrapperSiteSafetySupervisor" style="margin-bottom: 10px">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>대상물 코드</th>
										<th>대상물명</th>
										<th>대상물 영문명</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, index) in modalObjects" :key="row.objectId">
										<td style="text-align: center">{{ row.objectId }}</td>
										<td>{{ row.objectNm }}</td>
										<td>{{ row.objectEngNm }}</td>
										<td style="text-align: center">
											<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteModalObject(index)">
												<b><i class="icon-minus3"></i></b>
												삭제
											</button>
										</td>
									</tr>
									<tr empty>
										<td colspan="4">대상물을 추가하지 않으면 전체 대상물입니다.</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="text-right">
							<button type="button" class="btn btn-labeled bg-primary" @click="saveModalObjects">
								<b><i class="icon-checkmark3"></i></b>
								저장
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiIndex from '../../api/index';
import Select2 from '@/components/Select2';
import _ from 'lodash';

let axiosExtention;

export default {
	components: {
		Select2: Select2,
	},
	data: () => ({
		pageParam: { loginUserSiteId: '' },
		siteMap: {},
		datas: {},
		siteInfoAndCctvInfoList: [],
		dataSource: [], //트리에 세팅할 데이터 소스
		isSite: true,
		isCctv: false,
		//현재 클릭한 현장명
		curSiteNm: '',
		//현재 클릭한 cctv명
		curCctvNm: '',
		//현재 클릭한 cctv의 비디오 src
		curCctvSrc: null,
		//현재 클릭한 cctv의 정보
		curCctvInfo: {
			cctvId: '',
			dangerZonePolygonCdnt: [],
			regDtm: '',
			regUser: '',
			updDtm: '',
			updUser: '',
			connPort: '',
		},
		//비디오 프레임을 보여줄 캔버스의 context
		videoCtx: null,
		//폴리곤을 그릴 캔버스의 context
		polygonCtx: null,
		frame: 30,
		//원본 비디오의 css 크기
		originVideoWidth: 0,
		originVideoHeight: 0,
		coordinates: [],
		//좌표 출력용 변수
		// clickpoints: '',
		// mouseClickPoints: '',
		// isCreate: false,
		isNotSavedYet: true,
		//현재 클릭한 좌표
		mouse: {},
		//점을 다 그린 후 드래그 할 때, 해당 좌표의 인덱스를 저장할 변수
		draggable: false,
		//캔버스에 그릴 수 있는 최대 포인트 수
		maxNumOfPoints: 30,
		//실제 캔버스의 크기
		actualCanvasWidth: 0,
		actualCanvasHeight: 0,
		//실제의 stage 크기를 저장할 변수
		stageWidth: 0,
		stageHeight: 0,
		//원본 css 비디오 크기 대비 css 캔버스의 비율
		ratio: 0,
		// 실제의 canvas 사이즈와 원본 비디오의 사이즈 비율 저장
		actualSizeRatio: { x: 0, y: 0 },
		//폴리곤 저장용
		// polygons: {},
		polygons: [],
		//위험지역명 저장용
		zoneNames: [],
		//위험지역명의 수
		zoneIndexForCount: 0,
		//삭제할 위험지역의 아이디
		deleteZones: [],
		//현재 포커스가 된 인풋창 인덱스
		activeInputIndex: 0,
		//폴리곤들의 중간위치값
		centerPointsInPolygons: [],
		//폴리곤의 값을 문자열로 변경한 데이터
		stringifiedPolygonDatas: [],
		//현재 찍은 위치->실제 비디오 크기에 맞게 변환한 좌표. polygons에 추가
		coordsArr: [],
		cctvInfoMapForLabel: {
			dangerZoneDvsnCd: '위험지역구분코드',
			updDtm: '수정일',
			updUser: '수정자',
		},
		searchVM: { dangerZoneDvsnCd: '' },
		option: {
			dangerZoneDvsnCd: null,
			useObjects: null, // modal에서 선택할 대상물들
		},
		treeData: null,
		//수정일, 수정자 아웃풋창을 업데이트하기 위한 key
		keyForRerenderingUpdOutput: 0,
		modifiedCoordIndex: null,
		countOfPolygonsSavedInDB: 0,
		beforeActiveIndex: null,
		placeholder: '',
		modalSelectObject: '', // modal에서 선택한 object
		modalObjects: [], // modal에서 추가한 object들
		zoneObjectLabels: [], // 위험지역명과 매치될 적용된 대상물
	}),
	created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.pageParam = JSON.parse(sessionStorage.getItem('cctvDangerAreaConfigPageParam'));
		this.option.useObjects = this.pageParam.useObjects;
	},
	mounted() {
		this.pageParam.dangerZoneDvsnCd.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});
		this.option.dangerZoneDvsnCd = this.pageParam.dangerZoneDvsnCd;
		this.polygonCtx = this.$refs.polygonLayer.getContext('2d');
		this.getTreeData();
		// this.videoCtx = this.$refs.videoLayer.getContext('2d');
	},
	watch: {
		'searchVM.dangerZoneDvsnCd': {
			handler(value, oldValue) {
				if (this.option.dangerZoneDvsnCd) {
					this.option.dangerZoneDvsnCd.forEach(e => {
						if (e.value == value) {
							this.placeholder = e.text + '명';
						}
					});
				}
				if (oldValue != '' && value != oldValue) {
					this.deleteZones = [];
					this.getCctvDatas();
				}
			},
		},
		//위험지역명 변경 감지
		zoneNames(newVal) {
			this.$nextTick(function () {
				if (
					this.activeInputIndex != null &&
					this.zoneNames[this.activeInputIndex] != undefined &&
					Object.keys(this.polygons).length == newVal.length &&
					newVal.length != 0
				) {
					this.polygons[this.activeInputIndex]['dangerZoneNm'] = this.zoneNames[this.activeInputIndex];
				}
				if (Object.keys(this.polygons).length == newVal.length) {
					this.reDrawLines();
				}
			});
		},
		//폴리곤을 그리던 중이면
		isNotSavedYet(value) {
			if (value == true) {
				window.addEventListener('keyup', this.removeCurrentlyWorkingPolygon);
				window.addEventListener('keydown', this.removeLastSpot);
			} else {
				window.removeEventListener('keyup', this.removeCurrentlyWorkingPolygon);
				window.removeEventListener('keydown', this.removeLastSpot);
			}
		},
		activeInputIndex(newVal, oldVal) {
			this.beforeActiveIndex = oldVal;
		},
	},
	methods: {
		//esc를 누르면 현재 그리고 있던 폴리곤이 지워짐
		removeCurrentlyWorkingPolygon(event) {
			if (event.key == 'Escape') {
				this.coordinates = [];
				this.coordsArr = [];
				this.drawPolygon();
				this.zoneNames.splice(this.polygons.length, 1);
			}
		},
		//ctrl + `를 누르면 마지막에 그렸던 점(및 선)이 지워짐
		removeLastSpot(event) {
			if (event.key == '`' && event.ctrlKey) {
				this.coordinates.pop();
				this.coordsArr.pop();
				this.drawPolygon();
				if (this.coordsArr.length == 0) {
					this.zoneNames.splice(this.polygons.length, 1);
				}
			}
		},
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('stage');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		// 대상물 수정 버튼 클릭 시 동작
		updateObjectIds(index) {
			// 초기화
			this.modalObjects = [];
			this.modalSelectObject = '';

			// modalObjects에 현재 누른 layer의 대상물 넣어주기
			for (let i = 0; i < this.option.useObjects.length; i++) {
				const object = this.option.useObjects[i];
				for (let l = 0; l < this.polygons[index].objectIds.length; l++) {
					const objectId = this.polygons[index].objectIds[l];

					if (object.objectId == objectId) {
						this.modalObjects.push(object);
					}
				}
			}

			// 가지고 있는 object를 넣고
			$('#objectSearch').modal({ backdrop: 'static', show: true, keyboard: false });

			// 모달이 닫힐 때 동작 1회 수행
			$('#objectSearch').one(
				'hide.bs.modal',
				function () {
					// DB 저장은 objectId만 하기 때문에 변환
					let objectIds = [];
					for (let i = 0; i < this.modalObjects.length; i++) {
						objectIds.push(this.modalObjects[i].objectId);
					}

					// 위험지역명 옆에 들어갈 적용된 대상물 세팅
					if (this.modalObjects.length == 0) {
						this.$set(this.zoneObjectLabels, index, '전체');
					} else {
						let label = '';
						for (let i = 0; i < this.modalObjects.length; i++) {
							if (i < 5) {
								label += this.modalObjects[i].objectId;
								if (i != this.modalObjects.length - 1 && i != 4) {
									label += ', ';
								}
							} else {
								label += '외 ' + (this.modalObjects.length - 5) + '개';
								break;
							}
						}
						this.$set(this.zoneObjectLabels, index, label);
					}

					// 대상물 수정이니까 다른 변수 말고 object만 변경
					this.$set(this.polygons[index], 'objectIds', _.cloneDeep(objectIds));
				}.bind(this),
			);
		},
		//선택 영역 삭제
		removeZone(index) {
			let lengthOfPolygons = this.polygons.length;
			if (lengthOfPolygons != 0 && this.polygons[index] != undefined && this.polygons[index].dangerZoneId != null) {
				this.deleteZones.push(this.polygons[index].dangerZoneId);
			}
			if (index < this.countOfPolygonsSavedInDB) {
				this.countOfPolygonsSavedInDB -= 1;
			}
			this.zoneNames.splice(index, 1);
			this.zoneObjectLabels.splice(index, 1);
			this.polygons.splice(index, 1);
			this.centerPointsInPolygons.splice(index, 1);
			this.zoneIndexForCount -= 1;
			this.activeInputIndex = null;
		},
		addFocusedEffectToCurInputBox(index) {
			let curActivePolygonZoneName = document.getElementById('zoneName' + index);
			if (curActivePolygonZoneName != null) {
				curActivePolygonZoneName.classList.add('active-polygon');
			}
		},
		removeFocusedEffectFromBeforeInputBox() {
			if (this.beforeActiveIndex != null && this.beforeActiveIndex < this.zoneNames.length) {
				let beforeActivePolygonZoneName = document.getElementById('zoneName' + this.beforeActiveIndex);
				if (beforeActivePolygonZoneName != null) {
					beforeActivePolygonZoneName.classList.remove('active-polygon');
				}
			}
		},
		//인풋창을 눌렀을 때
		focusedInput(index) {
			// this.savePolygon(); // 세이브 해주지 않으면 지금 그리고 있던 폴리곤을 그리지 않음
			// this.$nextTick(function () {
			this.activeInputIndex = index;
			if (this.isNotSavedYet == true) {
				this.drawPolygon();
			} else {
				this.reDrawLines(); //FIXME: savePolygon에서 reDrawLines를 호출하지만 activeInputIndex를 null로 초기화해 선택한 폴리곤을 반전시켜 그리지 않음. 그래서 재호출. 구조를 변경하면 굳이 두 번 호출하지 않아도 될 것 같다.
			}
			this.$nextTick(function () {
				this.addFocusedEffectToCurInputBox(index);
				this.removeFocusedEffectFromBeforeInputBox();
			});
			// });
		},
		//위험지역명을 그려줄 때 중간위치를 구하는 함수
		calculateCenterPoint(index) {
			let sumX = 0;
			let sumY = 0;
			let dangerZonePolygonCdntLength = this.polygons[index].dangerZonePolygonCdnt.length;
			for (let i = 0; i < dangerZonePolygonCdntLength; i++) {
				let coords = this.polygons[index].dangerZonePolygonCdnt[i];
				sumX += coords[0];
				sumY += coords[1];
			}
			return [sumX / dangerZonePolygonCdntLength, sumY / dangerZonePolygonCdntLength];
		},
		//위험지역명을 그려주는 함수
		drawTextBoxAtPolygon(index) {
			// this.polygonCtx.lineWidth = 1;
			let rectWidth = 120;
			let rectHeight = 40;
			if (this.centerPointsInPolygons[index] != undefined) {
				let rectX = this.centerPointsInPolygons[index][0] / this.actualSizeRatio.x - rectWidth / 2;
				let rectY = this.centerPointsInPolygons[index][1] / this.actualSizeRatio.y - rectHeight / 2;
				this.polygonCtx.fillStyle = 'rgba(166, 166, 166, 0.6)';
				this.polygonCtx.beginPath();
				this.polygonCtx.fillRect(rectX, rectY, rectWidth, rectHeight);
				this.polygonCtx.stroke();
				this.polygonCtx.font = '18px sans-serif';
				this.polygonCtx.textAlign = 'center';
				this.polygonCtx.textBaseline = 'middle';
				this.polygonCtx.fillStyle = '#000000';
				this.polygonCtx.fillText(this.zoneNames[index], rectX + rectWidth / 2, rectY + rectHeight / 2);
			}
		},
		//점을 2개 이하로 찍었을 때 지우는 함수
		deleteDrawingWhenDrawInappositly() {
			this.polygonCtx.clearRect(0, 0, this.actualCanvasWidth, this.actualCanvasHeight);
			if (Object.keys(this.polygons).length != 0) {
				this.reDrawLines();
			}
			this.zoneNames.splice(this.polygons.length, 1);
			this.coordsArr = [];
			this.coordinates = [];
		},
		reDrawLines() {
			this.polygonCtx.clearRect(0, 0, this.actualCanvasWidth, this.actualCanvasHeight);
			let lengthOfPolygons = Object.keys(this.polygons).length;
			for (let i = 0; i < lengthOfPolygons; i++) {
				if (this.polygons[i].dangerZonePolygonCdnt == null) {
					continue;
				}
				this.polygonCtx.beginPath();
				this.polygonCtx.moveTo(
					this.polygons[i].dangerZonePolygonCdnt[0][0] / this.actualSizeRatio.x,
					this.polygons[i].dangerZonePolygonCdnt[0][1] / this.actualSizeRatio.y,
				);
				let dangerZonePolygonCdntLength = this.polygons[i].dangerZonePolygonCdnt.length;
				for (let j = 0; j < dangerZonePolygonCdntLength; j++) {
					this.polygonCtx.lineTo(
						this.polygons[i].dangerZonePolygonCdnt[j][0] / this.actualSizeRatio.x,
						this.polygons[i].dangerZonePolygonCdnt[j][1] / this.actualSizeRatio.y,
					);
				}
				this.polygonCtx.closePath();
				this.setPolygonColor(i);
				this.polygonCtx.stroke();
				this.polygonCtx.fill();
				for (let j = 0; j < dangerZonePolygonCdntLength; j++) {
					this.polygonCtx.beginPath();
					this.polygonCtx.arc(
						this.polygons[i].dangerZonePolygonCdnt[j][0] / this.actualSizeRatio.x,
						this.polygons[i].dangerZonePolygonCdnt[j][1] / this.actualSizeRatio.y,
						5,
						0,
						2 * Math.PI,
					);
					this.polygonCtx.fill();
					this.polygonCtx.stroke();
				}
				this.drawTextBoxAtPolygon(i);
			}
		},
		//위험지역명 validation
		validateZoneNames() {
			let lengthOfzoneNames = this.zoneNames.length;
			for (let i = 0; i < lengthOfzoneNames; i++) {
				if (this.zoneNames[i] == 0 || this.zoneNames[i] == undefined) {
					alert(this.placeholder + '을 입력해주세요');
					return false;
				}
			}
			return true;
		},
		//폴리곤 저장 버튼을 눌렀을 때 폴리곤을 저장하는 함수. 실제로 데이터 저장x
		savePolygon() {
			if (this.coordsArr.length == 0) {
				return;
			}
			//점이 2개 이하면 지움.
			if (this.coordsArr.length < 3) {
				this.deleteDrawingWhenDrawInappositly();
				return;
			}

			// AI 탐지 지역 or AI 비탐지 지역만 해당
			if (this.searchVM.dangerZoneDvsnCd == '05' || this.searchVM.dangerZoneDvsnCd == '06') {
				// 초기화
				this.modalObjects = [];
				this.modalSelectObject = '';

				$('#objectSearch').modal({ backdrop: 'static', show: true, keyboard: false });

				// 모달이 닫힐 때 동작
				$('#objectSearch').one(
					'hide.bs.modal',
					function () {
						// DB 저장은 objectId만 하기 때문에 변환
						let objectIds = [];
						for (let i = 0; i < this.modalObjects.length; i++) {
							objectIds.push(this.modalObjects[i].objectId);
						}

						// 위험지역명 옆에 들어갈 적용된 대상물 설정
						if (this.modalObjects.length == 0) {
							this.zoneObjectLabels.push('전체');
						} else {
							let label = '';
							for (let i = 0; i < this.modalObjects.length; i++) {
								if (i < 5) {
									label += this.modalObjects[i].objectId;
									if (i != this.modalObjects.length - 1 && i != 4) {
										label += ', ';
									}
								} else {
									label += '외 ' + (this.modalObjects.length - 5) + '개';
									break;
								}
							}
							this.zoneObjectLabels.push(label);
						}

						this.polygons.push({
							dangerZoneId: null,
							cctvId: this.curCctvInfo.cctvId,
							dangerZoneNm: this.zoneNames[this.zoneNames.length - 1],
							dangerZoneDvsnCd: this.searchVM.dangerZoneDvsnCd,
							dangerZonePolygonCdnt: _.cloneDeep(this.coordsArr),
							objectIds: _.cloneDeep(objectIds),
						});
						this.centerPointsInPolygons.push(this.calculateCenterPoint(this.polygons.length - 1));
						this.isNotSavedYet = false;
						this.coordsArr = [];
						this.coordinates = [];
						// 모달 변수 초기화
						this.modalSelectObject = '';
						this.modalObjects = [];

						this.$nextTick(function () {
							if (this.zoneNames.length != 0) {
								let lastPolygonZoneName = document.getElementById('zoneName' + String(this.zoneNames.length - 1));
								if (lastPolygonZoneName != null) {
									lastPolygonZoneName.classList.remove('active-polygon');
								}
							}
						});

						this.activeInputIndex = null;
						this.reDrawLines();
					}.bind(this),
				);
			} else {
				this.polygons.push({
					dangerZoneId: null,
					cctvId: this.curCctvInfo.cctvId,
					dangerZoneNm: this.zoneNames[this.zoneNames.length - 1],
					//dangerZoneNm: this.zoneNames[this.zoneIndexForCount],
					dangerZoneDvsnCd: this.searchVM.dangerZoneDvsnCd,
					dangerZonePolygonCdnt: _.cloneDeep(this.coordsArr),
				});
				this.centerPointsInPolygons.push(this.calculateCenterPoint(this.polygons.length - 1));
				this.isNotSavedYet = false;
				this.coordsArr = [];
				this.coordinates = [];
				this.$nextTick(function () {
					if (this.zoneNames.length != 0) {
						let lastPolygonZoneName = document.getElementById('zoneName' + String(this.zoneNames.length - 1));
						if (lastPolygonZoneName != null) {
							lastPolygonZoneName.classList.remove('active-polygon');
						}
					}
				});
				// 좌표확인용
				// this.$refs.pointsInfo.innerHTML = 'No points yet';
				// this.$refs.mousePoints.innerHTML = 'No points yet';
				// this.clickpoints = '';
				// this.mouseClickPoints = '';
				// this.polygonCtx.beginPath();
				// this.zoneIndexForCount += 1;
				this.activeInputIndex = null;
				this.reDrawLines();
			}
		},
		// select box에 있는 대상물 대상물 목록에 추가
		addModalObject() {
			if (this.modalSelectObject == '') {
				return;
			}

			const object = JSON.parse(this.modalSelectObject);

			for (let i = 0; i < this.modalObjects.length; i++) {
				// 이미 등록된 대상물은 등록 안되게 설정
				if (this.modalObjects[i].objectId == object.objectId) {
					alert('존재하는 대상물입니다.');
					return false;
				}
			}

			this.modalObjects.push(object);
		},
		// 모달 창에서 대상물 목록 삭제 버튼
		deleteModalObject(index) {
			// 대상물 객체 하나 삭제
			this.modalObjects.splice(index, 1);
		},
		// 모달 창 닫기 버튼
		closeModal() {
			// 닫기 전
			if (confirm('대상물을 선택하지 않고 닫으면 전체 대상물을 대상으로 합니다.\n 정말 닫으시겠습니까?')) {
				// 초기화
				this.modalSelectObject = '';
				this.modalObjects = [];
				$('#objectSearch').modal('hide');
			}
		},
		// 모달 창 저장 버튼
		saveModalObjects() {
			if (this.modalObjects.length == 0) {
				if (confirm('대상물을 선택하지 않고 저장하면 전체 대상물을 대상으로 합니다.\n 정말 닫으시겠습니까?')) {
					// 초기화
					this.modalSelectObject = '';
					this.modalObjects = [];
					$('#objectSearch').modal('hide');
				}
			} else {
				$('#objectSearch').modal('hide');
			}
		},
		//폴리곤을 처음 클릭시
		startDrawPolygon() {
			this.isNotSavedYet = true;
			this.zoneNames.push('zone');
			this.$nextTick(function () {
				this.addFocusedEffectToCurInputBox(String(this.zoneNames.length - 1));
				this.removeFocusedEffectFromBeforeInputBox();
			});
			this.activeInputIndex = null;
			// this.reDrawLines();
		},
		//폴리곤 데이터를 문자열로 저장하기 위해
		stringifyPolygons() {
			this.stringifiedPolygonDatas = _.cloneDeep(this.polygons);
			this.stringifiedPolygonDatas.forEach(e => {
				e.dangerZonePolygonCdnt = JSON.stringify(e.dangerZonePolygonCdnt);
			});
			if (this.stringifiedPolygonDatas.length != 0) {
				this.$set(this.stringifiedPolygonDatas[0], 'deleteZones', this.deleteZones);
				return;
			}
			this.stringifiedPolygonDatas.push({ deleteZones: this.deleteZones });
		},
		//데이터 저장
		async saveData() {
			//폴리곤을 저장하지 않았으면
			if (this.isNotSavedYet == true) {
				this.savePolygon(); // 작성중이던 폴리곤 저장
			}
			var closure = this;
			if (this.validateZoneNames() == true) {
				await this.stringifyPolygons();
				let wrapper = new Object();
				wrapper.listOfInsCctvDangerAreaConfigInVO = this.stringifiedPolygonDatas;
				await this.$axios
					.post(apiIndex.cctvDangerAreaConfig.insPolygonDatas, wrapper)
					.then(function (r) {
						if (r.data >= 1) {
							closure.getCctvDatas();
							closure.keyForRerenderingUpdOutput += 1;
							alert('폴리곤 정보가 저장되었습니다');
						}
					})
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		//원본 이미지 저장, 캔버스 사이즈 리사이징
		setSize() {
			let closure = this;
			this.originVideoWidth = this.$refs.video.naturalWidth;
			this.originVideoHeight = this.$refs.video.naturalHeight;

			if (this.originVideoWidth == 0 || this.originVideoHeight == 0) {
				setTimeout(() => {
					closure.setSize();
				}, 300);
			} else {
				this.resizeCanvasSize();
				this.reDrawLines();
			}
		},
		//트리 데이터 가져오기
		async getTreeData() {
			const closure = this;
			this.siteInfoAndCctvInfoList = [];

			await this.$axios
				.post(apiIndex.cctvDangerAreaConfig.inqSiteInfoAndCctvInfo, {})
				.then(async function (r) {
					let lengthOfData = r.data.length;
					for (let i = 0; i < lengthOfData; i++) {
						closure.$set(closure.siteMap, r.data[i].siteNm, r.data[i].siteId);
						closure.siteInfoAndCctvInfoList.push({
							siteNm: r.data[i].siteNm,
							siteId: r.data[i].siteId,
							cctvNm: r.data[i].cctvNm,
							cctvId: r.data[i].cctvId,
							cctvConnUrl: r.data[i].cctvConnUrl,
							cctvPlayUrl: r.data[i].cctvPlayUrl,
							//TEMP
							// cctvPlayUrl: process.env.VUE_APP_CCTV_PLAY_URL + e.cctvPlayUrl.substr(e.cctvPlayUrl.lastIndexOf(':')),
							cctvResolution: r.data[i].cctvResolution,
							cctvConnPort: r.data[i].cctvConnPort,
						});
					}
				})
				// .bind(this)
				.catch(axiosExtention.buildErrorHandler());
			this.setDataSource();
		},
		//트리에 넣어줄 데이터 소스 세팅
		setDataSource() {
			// 혹시 있을 데이터 초기화
			this.dataSource = [];
			let id = 0;
			for (var siteElement in this.siteMap) {
				var site = {};
				site.id = id++;
				site.text = siteElement;
				site.expanded = true;
				site.spriteCssClass = 'site';
				site.key = this.siteMap[siteElement];

				let cctvs = this.siteInfoAndCctvInfoList.filter(item => item.siteNm == siteElement && item.cctvNm != null);
				let lengthOfCctvs = cctvs.length;
				var siteItems = [];
				for (let i = 0; i < lengthOfCctvs; i++) {
					var siteItem = {};
					siteItem.id = id++;
					siteItem.text = cctvs[i].cctvNm;
					siteItem.expanded = false;
					siteItem.spriteCssClass = 'cctv';
					siteItem.key = cctvs[i].cctvId;
					siteItem.parentSiteId = this.siteMap[siteElement];
					siteItem.parentSiteNm = siteElement;
					siteItem.cctvConnUrl = cctvs[i].cctvConnUrl;
					siteItem.cctvPlayUrl = cctvs[i].cctvPlayUrl;
					siteItem.cctvResolution = cctvs[i].cctvResolution;
					siteItem.cctvConnPort = cctvs[i].cctvConnPort;
					siteItems.push(siteItem);
				}
				site.items = siteItems;
				this.dataSource.push(site);
			}
			this.drawTreeView();
		},
		//트리 그리기
		drawTreeView() {
			$('#treeview-kendo').kendoTreeView({
				dataSource: this.dataSource,
				loadOnDemand: true,
				select: this.treeSelect,
			});
		},
		//현재 cctv정보 리셋용
		resetCurCctvInfo() {
			this.curCctvInfo = { cctvId: '', dangerZonePolygonCdnt: [], regDtm: '', regUser: '', updDtm: '', updUser: '', connPort: '' };
			this.curCctvSrc = null;
		},
		//버튼정보 리셋용
		resetBtn() {
			// this.isCreate = false;
			this.isNotSavedYet = false;
		},
		treeSelect(e) {
			this.treeData = $('#treeview-kendo').data('kendoTreeView').dataItem(e.node);
			// this.treeData = $('#treeview-kendo').data('kendoTreeView').dataItem(e.node);
			if (this.treeData.spriteCssClass == 'cctv') {
				// 현재 누른 것은 cctv
				this.isSite = false;
				//cctv 데이터 가져오기
				this.getCctvDatas();
				this.isCctv = true;
				this.resetBtn();
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
				// //기존 Cctv정보와 폴리곤 정보 리셋
			} else {
				// 현재 누른 것은 현장
				this.isSite = true;
				this.isCctv = false;
				this.resetBtn();
				this.curSiteNm = this.treeData.text;
				this.curCctvSrc = null;
			}
		},
		setUpdDtmAndUser(resultData) {
			let lastUpdatedData = resultData.reduce((a, b) => (new Date(a.updDtm) > new Date(b.updDtm) ? a : b));
			this.curCctvInfo.updDtm = lastUpdatedData.updDtm;
			this.curCctvInfo.updUser = lastUpdatedData.updUser;
		},
		//cctv 데이터 가져오기
		getCctvDatas() {
			var closure = this;
			//기존 폴리곤 정보 리셋
			this.originVideoWidth = 0;
			this.originVideoHeight = 0;
			this.clearPoints();
			this.deleteZones = [];
			this.countOfPolygonsSavedInDB = 0;
			this.resetCurCctvInfo();
			this.$axios
				.post(apiIndex.cctvDangerAreaConfig.inqCctvDangerArea, { cctvId: this.treeData.key, dangerZoneDvsnCd: this.searchVM.dangerZoneDvsnCd })
				.then(function (result) {
					if (result.data != '' && result.data != null) {
						closure.curCctvInfo = result.data[0];
						closure.setUpdDtmAndUser(result.data);
						closure.polygons = result.data;
						closure.countOfPolygonsSavedInDB = closure.polygons.length;
						closure.$set(closure.curCctvInfo, 'connPort', closure.treeData.cctvConnPort);
					}
					closure.setCctvDatas();
					// setSize를 하기 전에 stage를 보여주지 않기 위해 hidden을 줌
					//closure.$refs.stage.style.visibility = 'hidden';
					//이미지 소스를 세팅하기 전에 캔버스 사이즈를 세팅하는 것을 방지하기 위해 setTimeout을 줌 ->이미지 소스가 늦게 세팅됐을 때를 위한 방어용
					// let timerId = setInterval(() => {
					// 	closure.setSize();
					// 	closure.reDrawLines();
					// }, 300);
					// setTimeout(() => {
					// 	clearInterval(timerId);
					// 	if (closure.actualSizeRatio.x == 0 || closure.actualSizeRatio.y == 0) {
					// 		console.log(123123);
					// 		location.reload();
					// 	}
					// }, 4000);

					let index = 0;
					closure.polygons.forEach(e => {
						e.dangerZonePolygonCdnt = JSON.parse(e.dangerZonePolygonCdnt);
						closure.zoneNames.push(e.dangerZoneNm);
						closure.centerPointsInPolygons.push(closure.calculateCenterPoint(index++));

						if (e.objectIds.length == 0) {
							closure.zoneObjectLabels.push('전체');
						} else {
							let label = '';
							for (let i = 0; i < e.objectIds.length; i++) {
								if (i < 5) {
									label += e.objectIds[i];
									if (i != e.objectIds.length - 1 && i != 4) {
										label += ', ';
									}
								} else {
									label += '외 ' + (e.objectIds.length - 5) + '개';
									break;
								}
							}
							closure.zoneObjectLabels.push(label);
						}
					});
					closure.activeInputIndex = null;
					closure.setSize();
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//cctv 정보 세팅
		setCctvDatas() {
			this.curCctvNm = this.treeData.text;
			this.curSiteNm = this.treeData.parentSiteNm;
			this.curCctvInfo.cctvId = this.treeData.key;
			this.curCctvSrc = this.treeData.cctvPlayUrl.replace('infer', 'origin') + '/' + this.treeData.key;
		},
		//마우스를 클릭했을 때 좌표 저장
		handleMouseDown(e) {
			this.mouse = this.oMousePos(e);
			// if(this.isCreate) {
			let lengthOfCoordinates = this.coordinates.length;
			if (lengthOfCoordinates == 0) {
				this.startDrawPolygon();
			}
			for (let index = 0; index < lengthOfCoordinates; index++) {
				this.polygonCtx.beginPath();
				this.polygonCtx.arc(this.coordinates[index].x, this.coordinates[index].y, 5, 0, 2 * Math.PI);
				// 마우스가 원 안에 있으면 해당 포인트를 이동할 수 있다
				if (this.polygonCtx.isPointInPath(this.mouse.x, this.mouse.y)) {
					this.draggable = index + 1;
					this.modifiedCoordIndex = index;
					return;
				}
			}
			if (lengthOfCoordinates < this.maxNumOfPoints) {
				this.coordinates.push({ x: this.mouse.x, y: this.mouse.y });
				this.coordsArr.push([
					Math.round((((this.mouse.x / this.actualCanvasWidth) * 100) / 100) * this.originVideoWidth),
					Math.round((((this.mouse.y / this.actualCanvasHeight) * 100) / 100) * this.originVideoHeight),
				]);
			}
			//좌표 확인용
			// this.clickpoints +=
			// 	'[' +
			// 	(Math.round((this.mouse.x / this.actualCanvasWidth) * 100) / 100) * this.originVideoWidth +
			// 	', ' +
			// 	(Math.round((this.mouse.y / this.actualCanvasHeight) * 100) / 100) * this.originVideoHeight +
			// 	'] ';
			// this.mouseClickPoints +=
			// 	'[' +
			// 	Math.round((this.mouse.x / this.actualCanvasWidth) * 100) / 100 +
			// 	', ' +
			// 	Math.round((this.mouse.y / this.actualCanvasHeight) * 100) / 100 +
			// 	'] ';
			// this.$refs.pointsInfo.innerHTML = this.clickpoints;
			// this.$refs.mousePoints.innerHTML = this.mouseClickPoints;
			// }

			this.drawPolygon();
		},
		//마우스 위치
		oMousePos(evt) {
			var ClientRect = this.$refs.polygonLayer.getBoundingClientRect();
			return {
				x: Math.round(evt.clientX - ClientRect.left),
				y: Math.round(evt.clientY - ClientRect.top),
				// x: Math.round(evt.offsetX),
				// y: Math.round(evt.offsetY),
			};
		},
		//폴리곤 색깔 세팅
		setPolygonColor(index) {
			if (index == this.activeInputIndex) {
				// this.polygonCtx.strokeStyle = 'white';
				// this.polygonCtx.fillStyle = 'rgba(255, 255, 255, 0.5)';
				this.polygonCtx.strokeStyle = 'rgb(157, 218, 241)';
				this.polygonCtx.fillStyle = 'rgba(157, 218, 241, 0.6)';
				//위험지역
			} else if (this.searchVM.dangerZoneDvsnCd == '01') {
				this.polygonCtx.strokeStyle = 'red';
				this.polygonCtx.fillStyle = 'rgba(255, 0, 0, 0.5)';
				//2인1조
			} else if (this.searchVM.dangerZoneDvsnCd == '02') {
				this.polygonCtx.strokeStyle = 'blue';
				this.polygonCtx.fillStyle = 'rgba(255, 255, 0, 0.5)';
			}
		},
		//폴리곤 그리기
		drawPolygon() {
			this.polygonCtx.clearRect(0, 0, this.actualCanvasWidth, this.actualCanvasHeight);
			if (Object.keys(this.polygons).length > 0) {
				this.reDrawLines();
			}
			if (this.coordinates.length != 0) {
				this.polygonCtx.beginPath();
				this.polygonCtx.moveTo(this.coordinates[0].x, this.coordinates[0].y);
				for (let index = 1; index < this.coordinates.length; index++) {
					this.polygonCtx.lineTo(this.coordinates[index].x, this.coordinates[index].y);
				}
				this.polygonCtx.closePath();
				this.setPolygonColor(this.activeInputIndex);
				this.polygonCtx.stroke();
				this.polygonCtx.fill();
				//마우스를 찍은 위치에 동그라미 그려줌
				for (let index = 0; index < this.coordinates.length; index++) {
					this.polygonCtx.beginPath();
					this.polygonCtx.arc(this.coordinates[index].x, this.coordinates[index].y, 5, 0, 2 * Math.PI);
					this.polygonCtx.fill();
					this.polygonCtx.stroke();
				}
			}
		},
		mouseMove(e) {
			// if (this.isCreate) {
			if (this.draggable) {
				this.mouse = this.oMousePos(e);
				this.coordinates[this.draggable - 1].x = this.mouse.x;
				this.coordinates[this.draggable - 1].y = this.mouse.y;
				this.drawPolygon();
			}
			// }
		},
		setDraggableFalse() {
			if (this.draggable) {
				this.draggable = false;
				this.coordsArr[this.modifiedCoordIndex] = [
					Math.round((((this.mouse.x / this.actualCanvasWidth) * 100) / 100) * this.originVideoWidth),
					Math.round((((this.mouse.y / this.actualCanvasHeight) * 100) / 100) * this.originVideoHeight),
				];
				this.modifiedCoordIndex = null;
			}
		},
		clearPoints() {
			this.coordinates = [];
			this.coordsArr = [];
			// 좌표 확인용
			// this.$refs.pointsInfo.innerHTML = 'No points yet';
			// this.$refs.mousePoints.innerHTML = 'No points yet';
			// this.clickpoints = '';
			// this.mouseClickPoints = '';
			this.polygons = [];
			this.zoneNames = [];
			this.zoneObjectLabels = [];
			this.modalObjects = [];
			this.modalSelectObject = '';
			this.centerPointsInPolygons = [];
			this.resetBtn();
			this.polygonCtx.clearRect(0, 0, this.$refs.polygonLayer.width, this.$refs.polygonLayer.height);
			// 컨텍스트 리셋
			this.polygonCtx.beginPath();
		},
		//포인트 리셋
		clearPointsWithoutDBdatas() {
			//만약에 db에 있었던 폴리곤을 삭제한다면 (this.countOfPolygonsSavedInDB 인덱스 이전의 데이터를 삭제하면, this.countOfPolygonsSavedInDB의 갯수를 줄여줘야함)
			this.coordinates = [];
			this.coordsArr = [];
			let deletePolygons = this.polygons.slice(this.countOfPolygonsSavedInDB);
			deletePolygons.forEach(e => this.deleteZones.push(e.dangerZoneId));
			let delPolygonsCount = this.polygons.length - this.countOfPolygonsSavedInDB;
			this.polygons.splice(this.countOfPolygonsSavedInDB, delPolygonsCount);
			this.zoneNames.splice(this.countOfPolygonsSavedInDB, delPolygonsCount + 1); //폴리곤이 저장되기 전에 임시적인 위험지역명이 있을 수 있으므로
			this.zoneObjectLabels.splice(this.countOfPolygonsSavedInDB, delPolygonsCount);
			this.centerPointsInPolygons.splice(this.countOfPolygonsSavedInDB, delPolygonsCount);
			this.resetBtn();
			this.reDrawLines(); //삭제된 폴리곤을 제외하고 남은 폴리곤들을 다시 그리기 위해
		},
		//실제의 비디오 사이즈와 실제의 캔버스 사이즈에 맞게 비율값 구하기
		setActualSizeRatio() {
			//실제의 canvas 사이즈 측정
			this.actualCanvasWidth = Number(this.$refs.polygonLayer.getBoundingClientRect().width.toFixed(1));
			this.actualCanvasHeight = Number(this.$refs.polygonLayer.getBoundingClientRect().height.toFixed(1));
			//실제의 canvas 사이즈와 원본 비디오의 사이즈 비율 저장
			this.actualSizeRatio.x = Number(this.originVideoWidth / this.actualCanvasWidth).toFixed(3);
			this.actualSizeRatio.y = Number(this.originVideoHeight / this.actualCanvasHeight).toFixed(3);
		},
		//캔버스 사이즈 조절
		resizeCanvasSize() {
			this.stageWidth = this.$refs.stage.getBoundingClientRect().width;
			this.stageHeight = this.$refs.stage.getBoundingClientRect().height;
			//실제의 비디오 사이즈와 실제의 캔버스 사이즈에 맞게 비율값 구하기
			this.setActualSizeRatio();
		},
	},
};
</script>

<style>
.close {
	display: inline-block;
	width: 15px;
	color: #aaa;
	/* float: right; */
	font-size: 28px;
	font-weight: bold;
}
.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

.select-wrapper {
	min-width: 200px !important;
	width: 200px !important;
}

span.select2-selection.select2-selection--single[aria-disabled='true'] {
	background: #eee;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	width: 100%;
	margin-top: 5px;
	max-width: 200px;
}
.panel-body {
	text-align: center;
	margin-bottom: 10px !important;
}

.in-panel-body {
	display: inline-block;
	width: 1400px;
}

#pageTitle {
	display: inline-block;
}
.panel-flat {
	margin-bottom: 10px !important;
}
#rootVM > div > div {
	border: 1px solid #ddd;
}

#rootVM > div > .content {
	height: 740px;
	padding: 10px;
}

#treeView {
	width: 250px;
	height: 740px;
	margin-right: 1%;
	font-size: 15px;
	font-weight: bold;
	line-height: 15px;
	padding-top: 15px;
}

.contentRow {
	display: inline-block;
}

@media screen and (max-width: 680px) {
	.treeview-flex {
		flex: auto !important;
		width: 100%;
	}
}

#demo-section-title h3 {
	margin-bottom: 2em;
	text-align: center;
}

.treeview-flex h4 {
	color: #656565;
	margin-bottom: 1em;
	text-align: center;
}

#demo-section-title {
	width: 100%;
	flex: auto;
}

.treeview-flex {
	flex: 1;
	-ms-flex: 1 0 auto;
}

.k-treeview {
	max-width: 240px;
	margin: 0 auto;
}

.demo-section {
	margin-bottom: 5px;
	overflow: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

#stage {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1060px !important;
	height: 596px !important;
	position: relative;
	/* border: 2px solid black; */
	margin-bottom: 10px;
}

canvas {
	position: absolute;
	width: 1060px !important;
	height: 596px !important;
	margin-left: 130px;
}

#stage > img {
	position: absolute;
	width: 1060px;
	height: 596px;
	margin-left: 130px;
}

#polygon-layer {
	z-index: 0;
}

#video-layer {
	z-index: -1;
}

.site {
	background-size: 100%;
	background-image: url('/images/icon-site.png');
}
.cctv {
	background-size: 100%;
	background-image: url('/images/icon-cctv.png');
}

.contentWrap {
	display: flex;
	margin-bottom: 20px;
}

.active-polygon {
	background-color: rgb(157, 218, 241) !important;
}

.table {
	table-layout: fixed;
}
.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
	text-align: center;
}

.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
	text-align: left;
}

.panel-body .table td:last-child {
	padding: 0px;
}

.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 1.2em !important;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.modal-dialog {
	width: 760px !important;
}

.table > tbody > tr > td {
	border-bottom: 1px solid #ddd;
}
</style>
